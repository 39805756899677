import "../styles/Home.css";
import imgHome from "../assets/img/local/img-home.png";
import arroz from "../assets/img/local/arroz-rico.png";
import azucar from "../assets/img/local/azucar-blanca-valdez.png";
import alinio from "../assets/img/local/alinio-condimensa.png";
import { useNavigate } from "react-router-dom";

function Home() {

  const navigate = useNavigate();
  return (
    <section className="home-container">
      <div className="banner">
        <div className="text-content">
          <h1>Canastas</h1>
          <h2>al precio del mayorista</h2>
          <button type="button" className="btn-products-home" onClick={(e) => {navigate("/productos")}}>
            VER PRODUCTOS
          </button>
        </div>
        <div>
          <img className="img-canasta" src={imgHome} alt="Imagen de Canasta" />
        </div>
      </div>
      <h1 className="title2-header">PRODUCTOS DESTACADOS</h1>
      <div className="featured-products">
        <div>
          <img src={arroz} alt="imagen arroz rico" />
          <div>
            <p>Arroz Rico @</p>
            <p>$16.25</p>
          </div>
        </div>
        <div>
          <img src={alinio} alt="imagen aliño condimensa" />
          <div>
            <p>Aliño</p>
            <p>$0.95</p>
          </div>
        </div>
        <div>
          <img src={azucar} alt="imagen azucar valdez" />
          <div>
            <p>Azúcar Valdez 1Kg</p>
            <p>$52.00</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
