import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Categorias from "../components/Categorias";
import "../styles/Productos.css";
import axios from "axios";
import DetalleVenta from "../components/DetalleVenta.js";
import imagenDefault from "../assets/img/productos/default.png";
const BASEIMAGEN = require.context("../assets/img/productos");

const Products = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [listProducts, setListProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [articulosSelect, setArticulosSelect] = useState(() => {
    const savedCart = localStorage.getItem("carrito");
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [quantity, setQuantity] = useState(1);

  // Fetch de productos
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://docs.google.com/spreadsheets/d/e/2PACX-1vRV6s3YK7s9A67XJM7dc6qwBx6d-2PkO8VUpQEBWRhKTn7MxYro9xfGJNANWFAB3TASCqLU5QV8qcc9/pub?gid=985913335&single=true&output=csv"
        );

        // Parsear el CSV a un formato JSON
        const data = response.data.split("\n").map((row) => {
          const cols = row.split(",");
          return {
            // Asegúrate de que las claves coincidan con las de tu hoja de cálculo
            id: cols[0],
            NombreProducto: cols[1],
            PPU: cols[2],
            CPM: cols[3],
            PPM: cols[4],
            NombreImagen: cols[5],
            Categoria: cols[6],
          };
        });

        // Remover la primera fila (encabezados)
        const products = data.slice(1);
        setListProducts(products);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  // Sincronizar estado de categorías y página con la URL al cargar
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const categoriesParam = query.get("categories");
    const pageParam = query.get("page");
    setSelectedCategories(categoriesParam ? categoriesParam.split(",") : []);
    setCurrentPage(pageParam ? parseInt(pageParam, 10) : 1);
  }, [location.search]);

  // Filtrar productos cuando cambian las categorías seleccionadas o productos listados
  useEffect(() => {
    const filterProducts = () => {
      if (selectedCategories.length > 0) {
        return listProducts.filter((product) =>
          selectedCategories.includes(product.Categoria.replace("\r", ""))
        );
      }
      return listProducts;
    };
    setFilteredData(filterProducts());
  }, [selectedCategories, listProducts]);

  // Guardar el carrito en localStorage
  useEffect(() => {
    localStorage.setItem("carrito", JSON.stringify(articulosSelect));
  }, [articulosSelect]);

  // Cambiar la página y actualizar el parámetro `page` en la URL
  const handleNext = useCallback(() => {
    if (currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1;
        const query = new URLSearchParams(location.search);
        query.set("page", newPage);
        navigate({ search: query.toString() });
        return newPage;
      });
    }
  }, [
    currentPage,
    filteredData.length,
    itemsPerPage,
    location.search,
    navigate,
  ]);

  const handleBack = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1;
        const query = new URLSearchParams(location.search);
        query.set("page", newPage);
        navigate({ search: query.toString() });
        return newPage;
      });
    }
  }, [currentPage, location.search, navigate]);

  // Lógica de paginación
  const currentProducts = useMemo(() => {
    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
    return filteredData.slice(indexOfFirstProduct, indexOfLastProduct);
  }, [filteredData, currentPage, itemsPerPage]);

  // Añadir al carrito
  const handleAddToCart = useCallback(
    (product) => {
      setArticulosSelect((prevArticulos) => {
        const existingProduct = prevArticulos.find(
          (item) => item.NombreProducto === product.NombreProducto
        );
        if (existingProduct) {
          return prevArticulos.map((item) =>
            item.NombreProducto === product.NombreProducto
              ? { ...item, quantity: item.quantity + quantity }
              : item
          );
        } else {
          return [...prevArticulos, { ...product, quantity }];
        }
      });
    },
    [quantity]
  );

  const limpiarCarrito = () => {
    setArticulosSelect([]);
  };

  if (loading) return <div>Cargando productos...</div>;
  if (error) return <div>Error al cargar productos: {error.message}</div>;

  return (
    <div className="page-products">
      <Categorias
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
      <main className="products-container">
        <div className="show-products">
          <h2 className="text-xl font-semibold">
            Productos{" "}
            {selectedCategories.length > 0
              ? `de ${selectedCategories.join(", ")}`
              : " - Todos los Productos"}
          </h2>
          <div className="flex items-center space-x-2">
            <button
              className="btn-navigate"
              onClick={handleBack}
              disabled={currentPage <= 1}
              aria-label="Página anterior"
            >
              Anterior
            </button>
            <span>{currentPage}</span>
            <button
              className="btn-navigate"
              onClick={handleNext}
              disabled={currentProducts.length < itemsPerPage}
              aria-label="Siguiente página"
            >
              Siguiente
            </button>
          </div>
        </div>

        <div className="list-products">
          {currentProducts.length > 0 ? (
            currentProducts.map((product) => {
              return (
                <div key={product.id} className="products">
                  <div className="flex justify-center items-center h-32">
                    <img
                      src={
                        product.NombreImagen &&
                        BASEIMAGEN.keys().includes(`./${product.NombreImagen}`)
                          ? BASEIMAGEN(`./${product.NombreImagen}`)
                          : imagenDefault
                      }
                      alt={product.NombreProducto}
                      className="h-full justify-center items-center"
                    />
                  </div>
                  <h3 className="text-lg font-semibold">
                    {product.NombreProducto}
                  </h3>
                  <p className="text-sm text-gray-500">${product.PPU}</p>
                  <p className="text-sm text-gray-500">
                    Categoría: {product.Categoria}
                  </p>
                  <div className="flex justify-end mt-3">
                    <input
                      type="number"
                      min={1}
                      max={10}
                      placeholder="Cantidad"
                      step={1}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-20 p-2.5 mr-5"
                      onChange={(e) => setQuantity(Number(e.target.value))}
                    />
                    <button
                      className="text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br font-medium rounded-lg text-sm px-4 py-2"
                      aria-label="Añadir al carrito"
                      onClick={() => handleAddToCart(product)}
                    >
                      Añadir
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No hay productos disponibles.</p>
          )}
        </div>
      </main>
      <DetalleVenta
        articulos={articulosSelect}
        limpiarCarrito={limpiarCarrito}
        setArticulos={setArticulosSelect}
      />
    </div>
  );
};

export default Products;
