import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="footer-text">www.GalyStore.com</div>
    </footer>
  );
};

export default Footer;
