import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Categorias = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://docs.google.com/spreadsheets/d/e/2PACX-1vRV6s3YK7s9A67XJM7dc6qwBx6d-2PkO8VUpQEBWRhKTn7MxYro9xfGJNANWFAB3TASCqLU5QV8qcc9/pub?gid=1338930431&single=true&output=csv"
        );

        if (!response.ok) {
          throw new Error("Error fetching data from Google Sheets");
        }

        const data = await response.text();
        const rows = data.split("\n").slice(1); // Omitir la primera fila (encabezados)

        const categoryList = rows.map((row) => {
          const cols = row.split(",");
          return {
            id: cols[0].trim(),
            nombre: cols[1].trim(),
          };
        });

        setCategories(categoryList);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();

    const params = new URLSearchParams(location.search);
    const categoriesFromURL = params.get("categories");
    if (categoriesFromURL) {
      setSelectedCategories(categoriesFromURL.split(","));
    }
  }, [location.search]);

  const handleCategoryChange = (category) => {
    const updatedCategories = selectedCategories.includes(category)
      ? selectedCategories.filter((item) => item !== category)
      : [...selectedCategories, category];

    setSelectedCategories(updatedCategories);
    const params = new URLSearchParams();

    if (updatedCategories.length > 0) {
      params.append("categories", updatedCategories.join(","));
    }
    params.append("page", 1);
    navigate(`/productos?${params.toString()}`);
  };

  const clearFilters = () => {
    setSelectedCategories([]);
    navigate(`/productos?page=1`);
  };

  if (loading) {
    return <p>Cargando categorías...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <aside className="category-container">
      <h2 className="font-semibold text-lg">Filtrar Categorías</h2>
      <ul className="mt-4 space-y-2">
        {categories.length > 0 ? (
          categories.map((item) => (
            <li key={item.id} className="flex items-center">
              <input
                type="checkbox"
                id={item.nombre}
                name="category"
                checked={selectedCategories.includes(item.nombre)}
                onChange={() => handleCategoryChange(item.nombre)}
                className="mr-2"
                aria-label={`Filtrar por ${item.nombre}`}
              />
              <label htmlFor={item.nombre}>{item.nombre}</label>
            </li>
          ))
        ) : (
          <li>No hay categorías disponibles</li>
        )}
      </ul>
      <button
        onClick={clearFilters}
        className="py-2.5 px-5 mt-5 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700"
      >
        Limpiar Filtro
      </button>
    </aside>
  );
};

export default Categorias;
