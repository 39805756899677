const numeroTelefono = "+593999201044";

const generarMensaje = () => {
  
  let mensaje = '¡Hola!\n\n ¿Cómo puedo hacer un pedido?';
  const urlWhatsApp = `https://wa.me/${numeroTelefono}?text=${encodeURIComponent(mensaje)}`;  
  window.open(urlWhatsApp, '_blank');
};

const generarMensajePedido = (articulos, totalPVP) => {
  let mensaje = '¡Hola! Me gustaría hacer el siguiente pedido:\n\n';

  articulos.forEach(articulo => {
    mensaje += `• ${articulo.NombreProducto} x${articulo.quantity} - $${(articulo.PPU * articulo.quantity).toFixed(2)}\n`;
  });

  mensaje += `\nTotal: $${totalPVP.toFixed(2)}\n\nGracias.`;

  return encodeURIComponent(mensaje); // Codifica el mensaje para URL
};

const enviarPorWhatsApp = (articulos, totalPVP) => {
  const mensaje = generarMensajePedido(articulos, totalPVP);  
  const urlWhatsApp = `https://wa.me/${numeroTelefono}?text=${mensaje}`;
  window.open(urlWhatsApp, '_blank');
};

export { generarMensaje, generarMensajePedido, enviarPorWhatsApp };
