import React, { useMemo } from 'react';
import { enviarPorWhatsApp } from "../helpers/sendWhatsapp.js"; 
import "../styles/components.css";
const DetalleVenta = ({ articulos, limpiarCarrito, setArticulos }) => {
  
  // Calcular el total PVP con useMemo
  const { totalPVP } = useMemo(() => {
    return {
      totalPVP: articulos.reduce(
        (acc, curr) => acc + curr.PPU * curr.quantity,
        0
      ),     
    };
  }, [articulos]);

  // Verificar si el total cumple con el mínimo de $10 para habilitar el pedido
  const isPedidoHabilitado = totalPVP >= 10;

  // Función para eliminar un producto del carrito
  const deleteProductCart = (productToDelete) => {
    const nuevosArticulos = articulos.filter(
      (articulo) => articulo.NombreProducto !== productToDelete.NombreProducto
    );
    setArticulos(nuevosArticulos);
    localStorage.removeItem(productToDelete.NombreProducto);
  };

  // Función para solicitar pedido y enviar por WhatsApp
  const solicitarPedido = () => {
    enviarPorWhatsApp(articulos, totalPVP);  // Usa los artículos actuales y el totalPVP
  }

  return (
    <aside className="detail-sell-container">
      <h2 className="font-semibold text-xl mb-4">Detalle de Venta</h2>
      
      <button
        className="bg-red-500 text-white p-2 mb-4 w-full rounded hover:bg-red-600 transition-all"
        onClick={limpiarCarrito}
        disabled={articulos.length === 0}
      >
        Limpiar Carrito
      </button>

      {articulos.length === 0 ? (
        <p className="text-gray-500">No hay productos en el carrito.</p>
      ) : (
        <ul className="space-y-4 mb-6">
          {articulos.map((articulo) => (
            <li
              key={articulo.NombreProducto}
              className="flex justify-between items-center text-sm border-b pb-2"
            >
              <span className="font-medium">
                {articulo.NombreProducto} x{articulo.quantity}
              </span>
              <span className="font-semibold">
                ${ (articulo.PPU * articulo.quantity).toFixed(2) }
              </span>
              <button
                className="bg-red-500 text-white mx-2 px-2 py-1 rounded hover:bg-red-600 transition-all"
                onClick={() => deleteProductCart(articulo)}
              >
                X
              </button>
            </li>
          ))}
        </ul>
      )}

      {articulos.length > 0 && (
        <>
          <div className="text-lg font-semibold flex justify-between mb-2">
            <span>Total PVP</span>
            <span>${totalPVP.toFixed(2)}</span>
          </div>    

          <button
            className={`p-2 w-full rounded text-white font-medium transition-all ${isPedidoHabilitado
              ? 'bg-blue-500 hover:bg-blue-600'
              : 'bg-gray-400 cursor-not-allowed'
            }`}
            disabled={!isPedidoHabilitado}
            onClick={solicitarPedido} 
          >
            Solicitar Pedido
          </button>
          {!isPedidoHabilitado && (
            <p className="text-sm text-red-500 mt-2">
              * El pedido mínimo es de $10 dólares
            </p>
          )}
        </>
      )}
    </aside>
  );
};

export default DetalleVenta;
