import "./App.css";
import "./styles/components.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Products from "./pages/Products.js";
import Home from "./pages/Home.js";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import Navbar from "./components/Navbar.js";

export default function App() {
  const optionsNav = [
    "Arroz y Sal",
    "Aseo Personal",
    "Balanceado Animales",
    "Cafés y Solubles",
    "Condimentos",
    "Plásticos",
    "Cigarrilos y Licores",
    "Snacks",
  ];

  return (
    <div>
      <Header />
      <BrowserRouter>
        <Navbar options={optionsNav} />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* Rutas unificadas para productos */}
          <Route path="/productos" element={<Products />} />
          <Route path="/productos/:category" element={<Products />} />
          <Route path="/productos/:category/:page" element={<Products />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}
