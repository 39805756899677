import React from "react";
import logo from "../assets/img/local/logo.png";
import whatsapp from "../assets/img/local/logo-whatsapp.png";
import iconSearch from "../assets/img/local/icon-search.png";
import { generarMensaje } from "../helpers/sendWhatsapp.js";

const Header = () => {
  const handleWhatsAppClick = (event) => {
    event.preventDefault(); // Evita que el navegador navegue a otro enlace
    generarMensaje();
  };

  return (
    <header>
      <div className="header-1">
        <span>www.GalyStore.com</span>
      </div>
      <div className="header-2">
        <div>
          <a href="/">
            <img src={logo} alt="logo tienda de Galy" />
          </a>
        </div>
        <div className="search-container">          
          <input
            className="search-input"
            type="text"
            placeholder="Ingrese el nombre de un producto..."
          />
          <button type="button" className="search-button">
            <img src={iconSearch} alt="Buscar" />
          </button>        
        </div>
        <div>
          <button onClick={handleWhatsAppClick} className="btn-sendMessage">
            <img src={whatsapp} alt="Contacto Whatsapp" />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
