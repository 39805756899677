function Navbar({ options }) {
  return (
    <nav className="navbar">
      <ul>
        {options.map((name, index) => (
          <li key={index}>
            {/* Crear un enlace con la categoría y la página como parámetros */}
            <a href={`/productos?categories=${name.replace(/\s+/g, " ")}&page=1`}>
              {name}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navbar;
